import {
  Box,
  Grid,
  MenuItem,
  Select,
  Stack,
  TableCell,
  Typography,
  useTheme,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import api from "../../api/api";
import {
  getClassesByTerm,
  getStudentClasses,
  getStudentClassInstances,
} from "../../api/classInstanceApi";
import { getAllTerms } from "../../api/termApi";
import { getUsers, ROLE_STUDENT } from "../../api/userApi";
import HsTable from "../../components/common/HsTable";
import LoadingIndicator from "../../components/common/LoadingIndicator";
import { useReportContext } from "../../contexts/report-context";
import { useAsyncOperation } from "../../hooks/useAsyncOperation";
import { debug } from "../../utils/debug";

const GradeBook = () => {
  const theme = useTheme();
  const { setMessage } = useReportContext();
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { selectedAccountId } = useSelector((state) => state.auth);

  // Add refs to track current filter values
  const selectedTermRef = useRef(null);
  const selectedStudentRef = useRef(null);

  // Update refs when state changes
  useEffect(() => {
    selectedTermRef.current = selectedTerm;
    debug.class.log("Term ref updated:", selectedTermRef.current);
  }, [selectedTerm]);

  useEffect(() => {
    selectedStudentRef.current = selectedStudent;
    debug.class.log("Student ref updated:", selectedStudentRef.current);
  }, [selectedStudent]);

  // Fetch terms
  const {
    execute: fetchTerms,
    data: terms,
    loading: loadingTerms,
    error: termsError,
  } = useAsyncOperation(async (signal) => {
    if (!selectedAccountId) return;
    const response = await getAllTerms(selectedAccountId, 0, 100, signal);
    return response.data;
  });

  // Fetch students
  const {
    execute: fetchStudents,
    data: students,
    loading: loadingStudents,
    error: studentsError,
  } = useAsyncOperation(async (signal) => {
    if (!selectedAccountId) return;
    const response = await getUsers(selectedAccountId, ROLE_STUDENT, signal);
    return response;
  });

  // Fetch classes
  const {
    execute: fetchClasses,
    data: classesData,
    loading: loadingClasses,
    error: classesError,
  } = useAsyncOperation(async (signal) => {
    if (!selectedAccountId) return null;

    debug.class.log("Fetch starting with:", {
      termRef: selectedTermRef.current,
      studentRef: selectedStudentRef.current,
      termState: selectedTerm,
      studentState: selectedStudent,
    });

    if (selectedStudentRef.current?.id) {
      if (selectedTermRef.current?.id) {
        // If both student and term are selected, use the base endpoint with termId param
        return await getStudentClassInstances(
          selectedStudentRef.current.id,
          selectedAccountId,
          selectedTermRef.current.id,
          page,
          rowsPerPage,
          signal
        );
      } else {
        // If only student is selected - get current term classes
        return await getStudentClasses(
          selectedStudentRef.current.id,
          selectedAccountId,
          null,
          page,
          rowsPerPage,
          signal
        );
      }
    } else if (selectedTermRef.current?.id) {
      return await getClassesByTerm(
        selectedTermRef.current.id,
        selectedAccountId,
        page,
        rowsPerPage,
        signal
      );
    }

    const params = {
      account_id: selectedAccountId,
      page,
      size: rowsPerPage,
      sortBy: "termSummary.startDate",
      sortDirection: "DESC",
    };

    const response = await api.get("/api/v1/class-instance", {
      params,
      signal,
    });
    return response.data;
  }, []);

  // Initial data fetch for terms and students
  useEffect(() => {
    if (selectedAccountId) {
      Promise.all([
        fetchTerms().catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            setMessage({
              text: error.message || "Failed to fetch terms",
              severity: "error",
              flag: true,
            });
          }
        }),
        fetchStudents().catch((error) => {
          if (error.code !== "ERR_CANCELED") {
            setMessage({
              text: error.message || "Failed to fetch students",
              severity: "error",
              flag: true,
            });
          }
        }),
      ]);
    }
  }, [selectedAccountId, fetchTerms, fetchStudents, setMessage]);

  // Fetch classes whenever filters change
  useEffect(() => {
    if (selectedAccountId) {
      console.log("Fetching with term:", selectedTerm?.id);
      fetchClasses().catch((error) => {
        if (error.code !== "ERR_CANCELED") {
          setMessage({
            text: error.message || "Failed to fetch classes",
            severity: "error",
            flag: true,
          });
        }
      });
    }
  }, [
    selectedAccountId,
    selectedTerm,
    selectedStudent,
    page,
    rowsPerPage,
    fetchClasses,
    setMessage,
  ]);

  // Handle term selection
  const handleTermChange = (e) => {
    const termId = e.target.value;
    const term = termId === "" ? null : terms?.find((t) => t.id === termId);
    setSelectedTerm(term);
    setPage(0);
  };

  // Handle student selection
  const handleStudentChange = (e) => {
    const student =
      e.target.value === ""
        ? null
        : students?.find((s) => s.id === e.target.value);
    setSelectedStudent(student);
    setPage(0);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  const columns = useMemo(
    () => [
      { id: "studentName", label: "Student", width: "20%" },
      { id: "className", label: "Class", width: "25%" },
      { id: "termName", label: "Term", width: "20%" },
      { id: "grade", label: "Grade", width: "15%" },
      { id: "status", label: "Status", width: "20%" },
    ],
    []
  );

  const renderDesktopRow = useCallback(
    (row) => (
      <>
        <TableCell>
          {row.students && row.students.length > 0
            ? row.students
                .map((student) => `${student.firstName} ${student.lastName}`)
                .join(", ")
            : "N/A"}
        </TableCell>
        <TableCell>{row.lessonPlanSummary?.name || "N/A"}</TableCell>
        <TableCell>{row.termSummary?.name || "N/A"}</TableCell>
        <TableCell>
          <Box
            sx={{
              backgroundColor: theme.palette.primary.lighter,
              color: theme.palette.primary.main,
              borderRadius: "16px",
              padding: "4px 12px",
              display: "inline-block",
              fontWeight: 500,
            }}
          >
            {row.grade || "Not Graded"}
          </Box>
        </TableCell>
        <TableCell>
          <Box
            sx={{
              backgroundColor:
                row.status === "COMPLETED"
                  ? theme.palette.success.light
                  : theme.palette.warning.light,
              color:
                row.status === "COMPLETED"
                  ? theme.palette.success.main
                  : theme.palette.warning.main,
              borderRadius: "16px",
              padding: "4px 12px",
              display: "inline-block",
              fontWeight: 500,
            }}
          >
            {row.status || "In Progress"}
          </Box>
        </TableCell>
      </>
    ),
    [theme]
  );

  const getStudentLabel = (option) => {
    if (!option) return "";
    return `${option.firstName} ${option.lastName}`;
  };

  const getTermLabel = (option) => {
    if (!option) return "";
    return option.name || "";
  };

  if (!selectedAccountId || loadingTerms || loadingStudents) {
    return <LoadingIndicator message="Loading grade book..." />;
  }

  if (termsError || studentsError) {
    return (
      <Typography color="error">
        Error loading data: {termsError || studentsError}
      </Typography>
    );
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h2" gutterBottom>
            Grade Book
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="stretch"
          >
            <Select
              size="small"
              value={selectedTerm?.id || ""}
              onChange={handleTermChange}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) return "All Terms";
                const term = terms?.find((t) => t.id === selected);
                return term?.name || "All Terms";
              }}
              sx={{
                minWidth: { xs: "100%", sm: 200 },
                "& .MuiOutlinedInput-root": {
                  height: "42px",
                },
              }}
            >
              <MenuItem value="">All Terms</MenuItem>
              {terms?.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              size="small"
              value={selectedStudent?.id || ""}
              onChange={handleStudentChange}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) return "All Students";
                const student = students?.find((s) => s.id === selected);
                return student
                  ? `${student.firstName} ${student.lastName}`
                  : "All Students";
              }}
              sx={{
                minWidth: { xs: "100%", sm: 200 },
                "& .MuiOutlinedInput-root": {
                  height: "42px",
                },
              }}
            >
              <MenuItem value="">All Students</MenuItem>
              {students?.map((student) => (
                <MenuItem key={student.id} value={student.id}>
                  {`${student.firstName} ${student.lastName}`}
                </MenuItem>
              ))}
            </Select>
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <HsTable
            columns={columns}
            data={classesData?.content || []}
            totalCount={classesData?.totalElements || 0}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={handlePageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            loading={loadingClasses}
            error={classesError}
            renderDesktopRow={renderDesktopRow}
            emptyMessage="No classes found"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GradeBook;
